// Vendor
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import resize from "@alpinejs/resize";
Alpine.plugin(focus);
Alpine.plugin(resize);
window.Alpine = Alpine;
window.Alpine.start();

import "lazysizes/plugins/respimg/ls.respimg"
import "lazysizes/plugins/parent-fit/ls.parent-fit"
import "lazysizes"
import "lazysizes/plugins/object-fit/ls.object-fit"

import { Fancybox } from "@fancyapps/ui"

// Modules
import "./modules/splide"
import "./modules/accordion"
import "./modules/cursor-drag-open"
import "./modules/filters"
import "./modules/google-maps"
import "./modules/image-zoom"
import "./modules/marquee"
import "./modules/mouse-trace"
import "./modules/scroller"
import "./modules/svg-icon-sprite"
import "./modules/table-of-contents"
import "./modules/video-fit"
import "./modules/patches"

const htmlEl = document.querySelector("html");

if (("ontouchstart" in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
	htmlEl.classList.add("is-touch-supported");
}

// On load
document.addEventListener("DOMContentLoaded", () => {
	// Remove adopted focus apperance on mouseout
	document.querySelectorAll("[data-blur-on-click]").forEach(el => {
		el.addEventListener("click", ({ currentTarget }) => {
			currentTarget.blur();
		});
	});

	setTimeout(() => {
		htmlEl.classList.remove("initiating");
		htmlEl.classList.add("init");
		window.dispatchEvent(new Event("resize"));

		// Identifies duplicate fancyboxes (generally rendered from slider clones) then
		// creates separate gallery ids before binding fancybox
		document.querySelectorAll("[data-fancybox]").forEach(el => {
			const id = el.getAttribute("data-fancybox");
			const src = el.getAttribute("href");
			document.querySelectorAll(`[data-fancybox="${id}"][href="${src}"]`).forEach((dup, index) => {
				dup.setAttribute("data-fancybox", `${id}-${index}`);
			});
		});

		// Bind Fancybox
		Fancybox.bind("[data-fancybox]", {});
	}, 200);
});
