/**
 * Table Of Contents
 * v1.1.0
 * 
 * Used in conjunction with Scroller
 */
const tableOfContents = () => {
    const ATTR_TOC = "data-toc";
    const tocEls = document.querySelectorAll(`[${ATTR_TOC}]`);

    tocEls.forEach(el => {
        const scrollerEl = document.querySelector(`#${ el.getAttribute(ATTR_TOC) }`);
        scrollerEl.addEventListener("scroller-section-update", (e) => {
            el.dispatchEvent(new CustomEvent("update", e));
        });
    });
}

document.addEventListener("DOMContentLoaded", tableOfContents);

export default tableOfContents;
