import {
    elGetOffset,
    elSetCSS,
    elToggleClasses,
} from "../utils/element-helpers";

const cursorDragOpen = () => {
    if (document.querySelector("html").classList.contains("is-touch-supported")) return;

    const cursorEls = document.querySelectorAll("[data-cursor-drag-open]");

    cursorEls.forEach(cursorEl => {
        const containerEl = cursorEl.parentNode;
        const itemEls = containerEl.querySelectorAll("[data-cursor-drag-open-item]");
        
        containerEl.addEventListener("mousemove", ({ currentTarget, pageX, pageY }) => {
            const offset = elGetOffset(currentTarget);

            elSetCSS(containerEl, {
                "--x": (pageX - offset.left) + "px",
                "--y": (pageY - offset.top) + "px",
            });

            elToggleClasses(cursorEl, {
                "--dragging": cursorEl.classList.contains("--down"),
            });
        });

        containerEl.addEventListener("mousedown", () => {
            elToggleClasses(cursorEl, {
                "--down": true,
            });
        });

        containerEl.addEventListener("mouseup", () => {
            elToggleClasses(cursorEl, {
                "--down": false,
            });
        });

        itemEls.forEach(itemEl => {
            itemEl.addEventListener("mouseover", () => {
                elToggleClasses(cursorEl, {
                    "--open": true,
                });
            });

            itemEl.addEventListener("mouseout", () => {
                elToggleClasses(cursorEl, {
                    "--open": false,
                });
            });
        })
    });

    window.addEventListener("resize", () => {
        cursorEls.forEach(cursorEl => {
            const containerEl = cursorEl.parentNode;

            elSetCSS(containerEl, {
                "--w": containerEl.offsetWidth + "px",
            });
        });
    });
}

document.addEventListener("DOMContentLoaded", cursorDragOpen);

export default cursorDragOpen;
