import {
    elGetOffset,
    elSetCSS,
} from "../utils/element-helpers";

/**
 * Image Zoom
 * v1.0.0
 */
const imageZoom = () => {
    let parentOffset, w, h, x, y, pX, pY;

    document.querySelectorAll("[data-zoom]").forEach((el) => {
        el.addEventListener("mousemove", ({ currentTarget, pageX, pageY }) => {
            parentOffset = elGetOffset(currentTarget.parentNode);
            w = currentTarget.offsetWidth;
            h = currentTarget.offsetHeight;
            x = pageX - parentOffset.left;
            y = pageY - parentOffset.top;
            x = x < 0 ? 0 : x;
            y = y < 0 ? 0 : y;
            pX = 100 / (w / x);
            pY = 100 / (h / y);
    
            elSetCSS(currentTarget.querySelector("[data-zoom-image] picture"), {
                transform: `translate(calc(${ x }px - ${ pX }%), calc(${ y }px - ${ pY }%))`,
            });
        });
    });
}

document.addEventListener("DOMContentLoaded", imageZoom);

export default imageZoom;
