import {
    elGetOffset,
    elSetCSS,
    elToggleClasses,
} from "../utils/element-helpers";

const mouseTrace = () => {
    if (document.querySelector("html").classList.contains("is-touch-supported")) return;
    let x, y, xp, yp, offset, timeout;

    const tracePos = (el, { pageX, pageY }) => {
        offset = elGetOffset(el);
        x = pageX - offset.left;
        y = pageY - offset.top;
        xp = x / el.offsetWidth;
        yp = y / el.offsetHeight;

        elSetCSS(el, {
            "--x": x + "px",
            "--y": y + "px",
            "--xp": xp,
            "--yp": yp,
        });
    };

    document.querySelectorAll("[data-mouse-trace]").forEach(el => {
        el.addEventListener("mousemove", (e) => {
            tracePos(el, e);

            elToggleClasses(el, {
                "--moving": true,
            });

            clearTimeout(timeout);
            timeout = setTimeout(() => {
                elToggleClasses(el, {
                    "--moving": false,
                });
            }, 300);
        });

        el.addEventListener("mouseover", (e) => {
            tracePos(el, e);
        });

        el.addEventListener("mouseout", () => {
            clearTimeout(timeout);
            elToggleClasses(el, {
                "--moving": false,
            });
        });
    });
}

document.addEventListener("DOMContentLoaded", mouseTrace);

export default mouseTrace;
