const googleMaps = () => {
    /**
     * initMap
     *
     * Renders a Google Map onto the selected element
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   el The element.
     * @return  object The map instance.
     */
    const initMap = (el) => {
        // Find marker elements within map.
        let markerEls = el.querySelectorAll(".marker");

        // Create gerenic map.
        let mapArgs = {
            zoom: parseInt(el.getAttribute("data-zoom")) || 16,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
			// mapId: "7f33d77a3f21488f",
			mapId: "5a4a02296cb76669",
        };
        let map = new google.maps.Map(el, mapArgs);

        // Add markers.
        map.markers = [];
        markerEls.forEach(markerEl => {
            initMarker(markerEl, map);
        });

        // Center map based on markers.
        centerMap(map);

        // Return map instance.
        return map;
    };

    /**
     * initMarker
     *
     * Creates a marker for the given element and map.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   el The element.
     * @param   object The map instance.
     * @return  object The marker instance.
     */
    const initMarker = (el, map) => {
        // Get position from marker.
        let latLng = {
            lat: parseFloat(el.getAttribute("data-lat")),
            lng: parseFloat(el.getAttribute("data-lng"))
        };

        // Create marker instance.
        let marker = new google.maps.marker.AdvancedMarkerElement({
            position: latLng,
            map: map,
        });

        // Append to reference for later use.
        map.markers.push(marker);

        // If marker contains HTML, add it to an infoWindow.
        if (el.innerHTML){
            // Create info window.
            let infowindow = new google.maps.InfoWindow({
                content: el.innerHTML
            });

            // Show info window when marker is clicked.
            google.maps.event.addListener(marker, "click", () => {
                infowindow.open(map, marker);
            });
        }
    };

    /**
     * centerMap
     *
     * Centers the map showing all markers in view.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   object The map instance.
     * @return  void
     */
    const centerMap = (map) => {
        // Create map boundaries from all map markers.
        const bounds = new google.maps.LatLngBounds();

        map.markers.forEach((marker) => {
            bounds.extend({
                lat: marker.position.lat,
                lng: marker.position.lng
            });
        });

        // Case: Single marker.
        if (map.markers.length == 1){
            map.setCenter(bounds.getCenter());
        // Case: Multiple markers.
        } else {
            map.fitBounds(bounds);
        }
    };

    // Render maps on page load.
    document.querySelectorAll(".g-map").forEach(el => {
        initMap(el);
    });
}

window.googleMaps = googleMaps;
