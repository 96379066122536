/**
 * Marquee
 * v1.1.0
 *
 * Requires: scroller.js
 */
const marquee = () => {
    const marqueeEls = document.querySelectorAll("[data-marquee]");
    if (!marqueeEls.length) return;

    marqueeEls.forEach(el => {
        const trackEl = el.querySelector("[data-marquee-track]");
        const cloneEl = trackEl.cloneNode(true);
		el.appendChild(cloneEl);
    });
}

document.addEventListener("DOMContentLoaded", marquee);

export default marquee;
