import {
    elSetCSS,
} from "../utils/element-helpers";

// Additional functionality to Alpine
// a11y tabbing
document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll("[data-accordion-trigger]").forEach(el => {
        document.querySelector(`#${el.getAttribute("aria-controls")}`).querySelectorAll("*").forEach(innerEl => {
            innerEl.setAttribute("tabindex", "-1");
        });

        el.addEventListener("click", ({ currentTarget }) => {
            const contentEl = document.querySelector(`#${currentTarget.getAttribute("aria-controls")}`);
            const expanded = currentTarget.getAttribute("aria-expanded") == "true";

            contentEl.querySelectorAll("*").forEach(contentInnerEl => {
                contentInnerEl.setAttribute("tabindex", expanded ? "" : "-1");
            });

            contentEl.animate([
                { height: contentEl.offsetHeight + "px" },
                { height: expanded ? contentEl.querySelector("*").offsetHeight + "px" : 0 },
            ], {
                duration: 400,
                easing: "ease-in-out",
                interations: 10,
            }).onfinish = () => {
                elSetCSS(contentEl, { height: expanded ? "auto" : 0 });
            };
        });
    });
});
